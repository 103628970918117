<template>
    <div class="device-type-stats">
        <!-- 视频播放容器 -->
        <div id="liveContainer" ref="liveContainer" v-loading="loading"> </div>
    </div>
</template>

<script>
export default {
    name: "DemoPlayer",
    component: {
    },
    props: {
        url: { type: String, default: "" },
        // 播放屏幕显示个数
        isShowOperateBtns: {
            type: Number,
            default: () => {
                return 1
            }
        },
        showPTZ: {
            type: Boolean,
            default: () => {
                return true
            }
        },
        devcieID: NaN,
        HorizonSpeed: {},
        VerticalSpeed: {},

    },
    watch: {
        isShowOperateBtns: {
            handler: async function () {
                // this.live.destroy();
                await this.create()
                await this.play()
            }
        },
        url(){
            this.play();
        },
    },
    data() {
        return {
            live: null,
            wasm: true,
            vc: "ff",
            playing: true,
            quieting: true,
            loaded: false, // mute
            showOperateBtns: true,
            err: "",
            speed: 0,
            performance: "",
            volume: 1,
            rotate: 0,
            vod: true,
            forceNoOffscreen: true,
            buffer: 0.2,
            changeResizeModel: false,
            cent_Show: false,
            loading: false,
            timeout: null,
            timeSlidertimer: null, // 可滑动时间轴
            timeCells: [
                {
                    beginTime: new Date().getTime() - 3 * 3600 * 1000,
                    endTime: new Date().getTime() - 1 * 3600 * 1000,
                    style: {
                        background: 'rgba(132, 244, 180, 0.498039)'
                    }
                },
                {
                    beginTime: new Date().getTime() - 6 * 3600 * 1000,
                    endTime: new Date().getTime() - 4 * 3600 * 1000,
                    style: {
                        background: 'rgba(132, 244, 180, 0.498039)'
                    }
                }
            ],
            stampStartTime: 0,
            stampEndTime: 0,
            newUrl: "",
        };
    },
    beforeDestroy() {
        this.destroyVideo();
    },
    async mounted() {
        // await this.create();
        // window.onerror = (msg) => (this.err = msg);
    },
    methods: {

        // 实例化 jessibuca 插件
        create() {
            let jessibucaDemo = null;
            jessibucaDemo = {
                container: this.$refs.liveContainer,
                videoBuffer: 0.2, // 设置最大缓冲时长，单位秒，播放器会自动消除延迟。
                videoBufferDelay: 1, // 视频缓冲区延迟
                isResize: true, // 当为true的时候：视频画面做等比缩放后,高或宽对齐canvas区域,画面不被拉伸,但有黑边。
                text: "",
                loadingText: "加载中", //  加载过程中文案。
                debug: false, //
                debugLevel: "debug",
                useMSE: true, // 否开启MediaSource硬解码
                useSIMD: false,
                useWCS: false, // 否开启Webcodecs硬解码
                showBandwidth: true, // 显示网速
                showPerformance: false,
                operateBtns: {  // 配置操作按钮 
                    fullscreen: this.showOperateBtns, // fullscreen 是否显示全屏按钮
                    screenshot: this.showOperateBtns,  // screenshot 是否显示截图按钮
                    play: this.showOperateBtns, // play 是否显示播放暂停按钮
                    audio: this.showOperateBtns, // audio 是否显示声音按钮
                    ptz: this.showPTZ, // 是否显示 上下左右控制器按钮
                    // performance:true, // 是否显示性能按钮
                    // zoom:true, // 是否显示 放大缩小
                    record: this.showOperateBtns, // record 是否显示录制按钮
                },
                // hasAudio:false,
                timeout: 10000, // 设置超时时长, 单位秒
                heartTimeoutReplayUseLastFrameShow: true,
                audioEngine: "worklet",
                qualityConfig: ['普清', '高清', '超清', '4K', '8K'],
                forceNoOffscreen: this.forceNoOffscreen, //是否不使用离屏模式（提升渲染能力）
                isNotMute: false,
                heartTimeout: 10, // 设置超时时长, 单位秒
                ptzClickType: "mouseDownAndUp", // 控制器类型: 点击显示
                ptzZoomShow: true, // 视频控制器按钮 左上, 右上, 右下, 左下
                ptzMoreArrowShow: false, // 是否显示ptz zoom(镜头) 按钮
                ptzApertureShow: true, // 是否显示ptz aperture（聚焦） 按钮
                ptzFocusShow: false, // 是否显示ptz focus（光圈） 按钮
                useCanvasRender: false,
                useWebGPU: true,
                demuxUseWorker: true,
            }
            this.live = new window.JessibucaPro(jessibucaDemo);

            // 暂停
            this.live.on("pause", () => {
                this.playing = false;
            });

            //暂停后重新播放
            this.live.on("play", async () => {
                if (!this.playing) {
                    // 接口请求url
                    if (!this.devcieID) return;
                    this.getNewUrl();
                }
                this.playing = true;
            });

            this.live.on("websocketError", (ms) => {
                console.log("监听成功!", ms);
            })

            this.live.on("websocketClose", async () => {
                if (this.RestartNum < 3) {
                    setTimeout(() => {
                        this.live.play(this.url)
                    }, 1000)
                } else {
                    return
                }
            })
            // jessibuca 控制上下左右按钮
            this.live.on("ptz",  (arrow) => {
                this.live.getPTZCmd(arrow)
                this.$emit('ptz', arrow)
                if (!this.devcieID) return;
                if (arrow != 'stop') {
                    this.$API.device.cameraControl.post(this.devcieID, {
                        Command: arrow + '',
                        HorizonSpeed: this.HorizonSpeed,
                        VerticalSpeed: this.VerticalSpeed,
                    })
                    // this.timeout = setInterval(async () => {
                    //     await this.$API.device.cameraControl.post(this.devcieID, {
                    //         Command: arrow + '',
                    //         HorizonSpeed: this.HorizonSpeed,
                    //         VerticalSpeed: this.VerticalSpeed,
                    //     })
                    // }, 200);
                } else {
                    clearTimeout(this.timeout)
                    this.$API.device.cameraControl.post(this.devcieID, {
                        Command: 'stop',
                        HorizonSpeed: 30,
                        VerticalSpeed: 30,
                    })
                }
            });

            // 返回卡顿程度
            this.live.on("performance", performance => {
                var show = "卡顿";
                if (performance === 2) {
                    show = "非常流畅";
                } else if (performance === 1) {
                    show = "流畅";
                }
                this.performance = show;
            });

        },
        destroy() {
            if(!this.live) return
            this.live.destroy();
            this.live = null;
        },
        // 播放视频
        play() {
            if (!this.url) return
            if (this.live) {
                this.live.destroy().then(() => {
                    this.create();
                    this.live.play(this.url);
                });
            } else {
                this.create();
                this.live.play(this.url);
            }
            
        },
        // 触发声音事件
        mute() {
            this.live.mute();
        },

        // 取消静音
        cancelMute() {
            this.live.cancelMute();
        },
        // 暂停
        pause() {
            this.live.pause();
            this.playing = false;
            this.err = "";
            this.performance = "";
        },

        // 设置音量
        volumeChange() {
            this.live.setVolume(this.volume);
        },

        // 旋转角度
        rotateChange() {
            this.live.setRotate(this.rotate);
        },

        // 截图
        screenShot() {
            try {
                const imgData = this.live.screenshot("test", "png", 0.92, 'base64');
                this.$emit("screenShot", imgData)
                return imgData
            } catch (e) {
                console.log("截图错误：", e)
                return null
            }
        },
        close() {
            if (this.live) {
                this.live.close();
            }
        },
        destroyVideo() {
            if( this.live ){
                this.live.destroy();
            }
            this.live = null;
            this.playing = false;
        },

        // 获取新的播放地址
        async getNewUrl() {
            let res = await this.$API.device_camera.play.get(this.devcieID, {
                play: "start"
            })
            if (res.data) {
                if (window.location.protocol == "http:") {
                    this.newUrl = res.data.ws_flv
                } else {
                    this.newUrl = res.data.wss_flv
                }
                if (!this.live) return
                this.live.play(this.newUrl)
            }
        }
    },

};
</script>

<style lang="scss" scoped>
.timeSlider {
    width: 99.8%;
    height: 30px;
}

.device-type-stats {
    height: 100%;
    width: 100%;
    background-color: rgba(243, 250, 250, .2);
    border-radius: 2px;
    backdrop-filter: blur(1px);
    position: relative;
}

#liveContainer {
    //  min-width: 300px;
    //  min-height: 500px;
    height: 100%;
    width: 100%;
}

.topBox {
    width: 70px;
    height: 70px;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: rgba(68, 157, 240, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    flex-wrap: wrap;
    border-radius: 15px;
    font-size: 18px;

    i {
        font-size: 45px;
    }

}
</style>
