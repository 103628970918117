import login from '../views/login/NewSignln/newLogin.vue'     //登录页
import ForgotPassword from '../views/login/ForgotPassword/ForgotPassword.vue'
import home from '../views/home/menu.vue'   //layout页面
import adminHome from '../views/adminHome/newHome/newHome.vue'

const basice_router = [
    {
        path: '/login',
        name: 'login',
        component: login,
        meta: {
            title: '登录页面'
        }
    },
    {
        path: '/ForgotPassword',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
            title: '注册页面'
        }
    },
    {
        path: '/ApplicationCenter',      //应用中心
        name: 'ApplicationCenter',
        component: () => import('../views/HomePage/BrightHomePage/ApplicationCenter/ApplicationCenter.vue'),
        meta: {
            dark: false,
        }
    },
    {                                      //接警平台
        path: '/AlarmPlatform',
        name: 'AlarmPlatform',
        component: () => import('../views/NewestHomePage/AlarmPlatform/AlarmPlatform.vue'),
        meta: {
            dark: false,
        }
    },
    {                                      //首页大屏
        path: '/digitalPage',
        name: 'digitalPage',
        component: () => import('../views/NewestHomePage/DataLargeScreen/DataLargeScreen.vue'),
        meta: {
            dark: false,
        }
    },
    {                           //子平台智慧消防页面
        path: '/FiremAlarmSystem',
        name: 'FiremAlarmSystem',
        component: () => import('../views/NewestHomePage/Children/FiremAlarmSystem/FiremAlarmSystem.vue'),
        meta: {
            dark: false,
        }
    },
    {                           //燃气安全子系统
        path: '/GasSafetySystem',
        name: 'GasSafetySystem',
        component: () => import('../views/NewestHomePage/Children/GasSafetySystem/GasSafetySystem.vue'),
        meta: {
            dark: false,
        }

    },
    {                           //生产子系统
        path: '/ProductionSystem',
        name: 'ProductionSystem',
        component: () => import('../views/NewestHomePage/Children/ProductionSystem/ProductionSystem.vue'),
        meta: {
            dark: false,
            role: [1, 2]
        }
    },
    {                    //巡检巡查子系统
        path: '/patrolSystem',
        name: 'patrolSystem',
        component: () => import('../views/NewestHomePage/Children/patrolSystem/patrolSystem.vue'),
        meta: {
            dark: false,
        }
    },
    {                    //视频监控子系统
        path: '/VideoSysttem',
        name: 'VideoSysttem',
        component: () => import('../views/NewestHomePage/Children/VideoSystem/index.vue'),
        meta: {
            dark: false,
        }
    },
    {                    //视频监控子系统
        path: '/GasSafetySystemA',
        name: 'GasSafetySystemA',
        component: () => import('../views/NewestHomePage/xiXiangGas/index.vue'),
        meta: {
            dark: false,
        }
    },
    {                    //视频监控子系统
        path: '/xixiangGas',
        name: 'xixiangGas',
        component: () => import('../views/NewestHomePage/xiXiangGas/homeScreen/index.vue'),
        meta: {
            dark: false,
        }
    },
    {                    //视频监控子系统
        path: '/multiScreenMonitoring',
        name: 'multiScreenMonitoring',
        component: () => import('@/views/multiScreenMonitoring/index.vue'),
    },
    {                    //视频监控子系统
        path: '/videoSystem',
        name: 'videoSystem',
        component: () => import('@/views/NewestHomePage/videoSystem/videoSystem.vue'),
    },
    {   // 监控子平台
        path: '/MonitoringSubPlatforms',
        name: 'MonitoringSubPlatforms',
        component: () => import('@/views/MonitoringSubPlatforms/index.vue'),
    },
    {   // MQTT监控子平台
        path: '/mqttVideo',
        name: 'mqttVideo',
        component: () => import('@/views/MqttVideo/index.vue'),
    },

    {   // 数据大屏系统
        path: '/largeDataScreen',
        name: 'largeDataScreen',
        component: () => import('@/views/largeDataScreen/index.vue'),
    },

    // {   // 数据大屏系统
    //     path: '/imgRecord',
    //     name: 'imgRecord',
    //     component: () => import('@/views/largeDataScreen/index.vue'),
    // },
    // {   // 数据大屏系统
    //     path: '/devCameraList',
    //     name: 'devCameraList',
    //     component: () => import('@/views/largeDataScreen/index.vue'),
    // },
    // 其他路由配置
    {
        path: '/',
        name: 'home',
        redirect: '/adminHome',
        meta: {
            title: "后台管理",
            icon: "icon-houtaiguanli",
        },
        component: home,
        children: [
            {
                name: "adminHome",
                path: '/adminHome',
                component: adminHome,
                meta: {
                    title: '后台首页',
                    icon: 'icon-shouye',
                },
            },
        ]
    },
]

export default basice_router