import config from "@/config"
import http from "@/utils/request"

export default{
   
    map:{
		url: `${config.API_URL}/siteRegion`,
		name: "新增值班",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    
    AGSH_lt: {
        url: `${config.API_URL}/AGSH-DX/queryAgshStatisticalData`,
        name: "设备总数，在线数量，在线率",
        get: async function () {
            return await http.get(this.url);
        }
    },

    AGSH_lb: {
        url: `${config.API_URL}/AGSH-DX/queryAgshTakePhotos`,
        name: "查询摄像头抓拍图片",
        get: async function (data) {
            return await http.get(this.url, data);
        }
    },

    AGSH_rt: {
        url: `${config.API_URL}/AGSH-DX/todayWarningInstance`,
        name: "今日报警次数，未处理报警次数",
        get: async function () {
            return await http.get(this.url);
        }
    },

    AGSH_rc: {
        url: `${config.API_URL}/AGSH-DX/AlarmTypeCount`,
        name: "报警类型分析，三天、本周、本月",
        get: async function (data) {
            return await http.get(this.url, data);
        }
    },

    AGSH_rb: {
        url: `${config.API_URL}/AGSH-DX/CaptureAnalysis`,
        name: "抓图分析 ，三天，本周，本月",
        get: async function (data) {
            return await http.get(this.url, data);
        }
    },
}