import config from "@/config"
import http from "@/utils/request"

export default {
    batchUpCamera: {
        url: `${config.API_GAS}/order/batch/upgradeCamera`,
        name: "批量下发远程升级摄像头",
        post: async function (data) {
            return await http.post(this.url, data);
        },
    },
    init_Gas: {
        url: `${config.API_URL}/init/industrialGas`,
        name: "批量初始化工业燃气",
        post: async function (data) {
            return await http.post(this.url, data);
        },
    },
    remotePhotoBatch: {
        url: `${config.API_GAS}/order/batch/remotePhotography`,
        name: "批量下发远程拍照",
        post: async function (data) {
            return await http.post(this.url, data, "application/json");
        },
    },
    cameraTimerBatch: {
        url: `${config.API_GAS}/order/batch/cameraTimer`,
        name: "批量下发定时时间拍照拍照",
        post: async function (data) {
            return await http.post(this.url, data, "application/json");
        },
    },

    batchSVS: {
        url: `https://iot.tranthing.com/undergroundGas/order/batch/setVibrateSensitivity`,
        name: "批量下发探测器的震动传感器灵敏度",
        post: async function (data) {
            return await http.post(this.url, data);
        },
    },
    batchSGU: {
        // url: `https://iot.tranthing.com/order/batch/setGatherUpload`,
        url: `https://iot.tranthing.com/undergroundGas/order/setGatherUpload`,
        name: '批量下发"地下空间及井下燃气探测器"指令, 心跳周期+采集周期',
        post: async function (data) {
            return await http.post(this.url, data);
        },
    },

    // 获取工业质检设备
    queryCheck: {
        url: `${config.API_URL}/industryDeviceCheck`,
        name: '获取工业质检设备',
        get: async function (data) {
            // deviceId =
            return await http.get(this.url, data);
        },
    },

    queryDevice: {
        url: `${config.API_URL}/importUpdateDeviceEntityLog/`,
        name: '查询批量修改设备单位日志',
        get: async function (data) {
            return await http.get(this.url, data);
        },
    },

    batchSetDevUnit: {
        url: `${config.API_URL}/massiveUpdateDeviceEntity/`,
        name: '批量修改设备单位',
        post: async function (data) {
            return await http.post(this.url, data);
        },
    }

}